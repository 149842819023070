<template>
  <div />
</template>

<script>
import router from '@/router'

export default {
  computed: {
    me() {
      return this.$store.getters['auth/getUser']
    },
  },
  mounted() {
    if (this.me.id) {
      this.$store.dispatch('auth/logout')
        .finally(() => {
          router.push({ name: 'login' })
          window.toast.notify.success(this.$t('auth.logout-success'))
        })
    } else {
      router.push({ name: 'login' })
    }
  },
}
</script>
